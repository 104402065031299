@import url("https://fonts.googleapis.com/css?family=Inter:wght@400;500;600");

#root {
    width: 100vw;
    height: 100vh;
}

body {
    margin: 0;
}

a,
span,
h1,
h2,
h3 {
    font-family: "Inter";
    font-weight: 400;
}

input {
    outline-color: #7a2182;
}

.layout {
    width: 100vw;
    height: 100vh;
    background-color: #eeeeee;
    position: relative;
    display: flex;
    flex-direction: column;

    > .header {
        width: 100%;
        height: 60px;
        min-height: 60px;
        background-color: white;
        box-sizing: border-box;
        border-bottom: 1px solid #c2c2c2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0 200px 0 200px;

        > .logo {
            position: absolute;
            left: 100px;
            top: 15px;
        }

        .real-dropdown {
            position: absolute;
            right: 100px;
        }
    }

    > .layout-grid {
        display: grid;
        grid-template-columns: 429px auto;
        grid-template-areas: "formframe previewframe";
        gap: 30px;
        width: 100%;
        height: 100%;
        min-width: 1800px;
        padding-left: 90px;
        padding-right: 90px;
        box-sizing: border-box;
        overflow-y: auto;
        padding-top: 40px;
        padding-bottom: 60px;

        > div {
            box-shadow: 0px 4px 3px 0px #00000014;
            background-color: white;
            border-radius: 20px;
            width: 100%;
            overflow-y: auto;
        }
    }
}
