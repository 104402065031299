.layout {
    > .header {
        > .layout-tabs {
            height: 100%;
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            padding-left: 35px;
            box-sizing: border-box;
            align-items: flex-end;
            justify-content: center;

            > span {
                height: 100%;
                padding-left: 12px;
                padding-right: 12px;
                box-sizing: border-box;
                cursor: pointer;
                border-bottom: 2px solid transparent;
                color: #505050;
                display: flex;
                align-items: center;
                font-size: 14px;
                transition: border-bottom 300ms ease-in;

                &.focused {
                    color: #7a2182;
                    font-weight: 700;
                    border-bottom: 2px solid #7a2182;
                    transition: border-bottom 300ms ease-in;
                }
            }
        }
    }
}
