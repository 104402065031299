.tabs-frame {
    grid-area: tabframe;
    height: 170px;
    display: flex;
    flex-direction: column;

    > .content {
        height: 118px;
        border-bottom: 1px solid #dbdbdb;
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 35px;
        padding-bottom: 23px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        > .title {
            color: black;
            font-size: 28px;
            font-weight: 500;
            line-height: 34px;
            letter-spacing: 0em;
        }

        > .subtitle {
            color: #757575;
            font-size: 15px;
            line-height: 18px;
            font-weight: 400;
        }
    }

    > .tabs {
        height: 100%;
        display: flex;
        flex-direction: row;
        column-gap: 28px;
        padding-left: 35px;
        box-sizing: border-box;
        align-items: flex-end;

        > span {
            padding-bottom: 13px;
            padding-left: 14px;
            padding-right: 14px;
            box-sizing: border-box;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            color: #505050;

            &.focused {
                color: #7a2182;
                font-weight: 700;
                border-bottom: 2px solid #7a2182;
            }
        }
    }
}
