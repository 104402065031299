.browser-content {
  .fake-loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 110;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 7px;

    > svg {
      width: 100px;
      height: 100px;
    }
  }
}