.website-frame {
    > .navbar-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;

        &.boxed {
            width: auto;
            margin-left: 40px;
            margin-right: 40px;
        }

        &.sticky {
            position: sticky;
            z-index: 80;
        }

        &.framed {
            margin-left: 15px;
            margin-right: 15px;
            width: auto;
        }

        > .navbar {
            position: relative;
            width: 100%;
            height: 56px;
            top: 0;
            z-index: 100;
            padding: 0 60px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 40px;
            align-items: center;

            > .navbar-items {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                height: 100%;

                > span {
                    position: relative;
                    padding: 0 10px;
                    color: white;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: 5px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    user-select: none;

                    > .menu {
                        position: absolute;
                        min-width: 200px;
                        display: none;
                        flex-direction: column;
                        align-content: center;
                        z-index: 50;
                        background-color: white;
                        color: black;
                        box-sizing: border-box;

                        &.drop-down {
                            border-bottom-left-radius: 5px;
                            border-bottom-right-radius: 5px;
                        }

                        &:hover {
                            display: flex;
                        }

                        > span {
                            padding: 0 40px 0 20px;
                            height: 40px;
                            line-height: 40px;
                            border-bottom: 1px solid #dee2e6;
                        }

                        > span:last-child {
                            border-bottom: none !important;
                        }
                    }

                    &:hover .menu {
                        display: flex;
                    }
                }
            }

            > .mega-menu {
                width: 100%;
                min-height: 200px;
                background-color: #ffffff;
                position: absolute;
                left: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                align-content: center;
                z-index: 50;
                padding: 20px 60px 40px 60px;
                box-sizing: border-box;
                box-shadow: 0px 4px 3px 0px #00000014;

                > span {
                    width: 300px;
                    padding: 0 40px 0 0px;
                    height: 40px;
                    line-height: 40px;
                    color: #212529;
                    border-bottom: 1px solid #dee2e6;
                    cursor: pointer;

                    &.indented {
                        padding-left: 20px;
                        width: 280px;
                    }
                }
            }

            > .navbar-buttons {
                display: flex;
                flex-direction: row;
                column-gap: 20px;

                &.burger-menu-special {
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    color: white;
                    font-weight: bolder;
                    font-size: 20px;
                }

                &.burger-menu-special-small {
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    color: white;
                    font-weight: bolder;
                    //font-size: 20px;
                }
            }

            > .navbar-logo {
                height: 100%;
            }

            &.logo-height {
                height: 100px;

                .navbar-logo {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    box-sizing: border-box;
                    position: absolute;

                    &.right {
                        right: 60px;
                    }

                    &.left {
                        left: 60px;
                    }

                    > svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
