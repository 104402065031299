.website-frame {
    > .header {
        position: relative;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;

        &.boxed {
            width: auto;
            margin-left: 40px;
            margin-right: 40px;
        }

        &.fixed {
            position: sticky;
            top: 0;
            z-index: 80;
        }

        &.sticky {
            position: sticky;
            top: 0;
            z-index: 80;
        }

        &.framed {
            // margin-top: 15px;
            margin-left: 15px;
            margin-right: 15px;
            width: auto;
        }

        &.border-top {
            border-top-width: 6px !important;
            border-top-style: solid !important;
        }

        > .quicklinks {
            position: relative;
            width: 100%;
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 40px;
            box-sizing: border-box;

            > span {
                border-left-width: 1px;
                border-left-style: solid;
                padding: 0 10px;
                font-size: 13px;
                font-weight: 400;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }

                &.active {
                    text-decoration: underline;
                }
            }

            > span:first-child {
                border-left: none;
            }
        }

        > .header-inner {
            width: 100%;
            height: 172px;
            padding: 0 60px;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 20px;
            justify-content: space-between;

            &.right {
                flex-direction: row-reverse;
            }

            > svg {
                width: 140px;
                height: 140px;
                position: relative;
                display: block;
            }

            > .buttons {
                display: flex;
                flex-direction: row;
                column-gap: 10px;
            }
        }
    }
}
