.icon-navigation {

  position: absolute;
  z-index: 99;
  right: 105px;
  top: 50%;
  display: flex;
  flex-direction: column;

  > span {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    justify-items: center;
    align-items: center;
    column-gap: 10px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    transition: width 0.3s ease-in-out;

    > span {
      min-width: 60px;
      text-align: right;
    }

    > svg {
      min-width: 45px;
    }

    &:hover {
      cursor: pointer;
      width: 200px;
      transition: width 0.3s ease-in-out;
    }
  }
}