.preview-frame {
    grid-area: previewframe;
    height: 100%;
    width: 100%;

    .site-preview-frame {
        height: 100%;
        display: flex;
        flex-direction: column;

        > .browser-header {
            overflow: hidden;
            height: 54px;
            width: 100%;
            border-radius: 20px 20px 0px 0px;
            background: #d8d8d8;
            position: relative;

            > .browser-bar {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 895px;
                height: 28px;
                border-radius: 40px;
                background: #ececec;
            }

            > .browser-buttons {
                position: absolute;
                top: 50%;
                left: 25px;
                transform: translateY(-50%);
                display: flex;
                flex-direction: row;
                column-gap: 7px;
            }
        }

        > .browser-content {
            height: 100%;
            width: 100%;
            overflow: auto;

            &.searchbar-overlay {
                position: relative;
                overflow: hidden;
            }
        }
    }
}
