.searchbar {
    height: 0px;
    overflow: hidden;
    padding: 0 40px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    z-index: 90;

    &.visible {
        height: 59px;
        padding: 10px 40px;
    }
}

.searchbar-popup-bg {
    z-index: 110;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    > .searchbar-popup-wrapper {
        position: relative;
        box-sizing: border-box;
        background-color: #ffffff;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        width: 600px;

        > .title {
            padding: 25px 30px;
            font-size: 22px;
            font-weight: 600;
            border-bottom: 1px solid #dee2e6;
        }

        > .searchbar-wrapper {
            padding: 25px 20px;
            padding-left: 20px;

            > .searchbar {
                padding-top: 10px;
                padding-right: 40px;
                padding-left: 10px;
            }
        }
    }
}
