.browser-content {
    .burger-menu {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #ffffff;
        z-index: 110;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        row-gap: 7px;

        > .title {
            color: #007bff;
            font-weight: 500;
            line-height: 1.2;
            font-size: 1.5rem;
        }

        > .item {
            height: 40px;
            line-height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            -moz-user-select: none;
            cursor: pointer;

            &.indented {
                padding-left: 24px;
            }
        }

        .close-icon {
            position: absolute;
            transition: all 0.2s ease-in-out;
            color: #007bff;
            right: 20px;
            top: 20px;
            cursor: pointer;
            display: flex;
            justify-items: center;

            &:hover {
                transform: scale(1.1);
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            }
        }

        .header-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            row-gap: 7px;
        }
    }
}
