.breadcrumb-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 !important;

    > .breadcrumb {
        padding: 15px 40px;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 7px;

        > span {
            &.chevron {
                display: flex;
            }
        }
    }

    > .title {
        font-size: 24px;
        font-weight: 600;
        padding: 20px 40px;
        box-sizing: border-box;
    }
}
