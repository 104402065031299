.form-frame {
    grid-area: formframe;
    height: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    row-gap: 20px;
    box-sizing: border-box;
}
