.colorPicker-frame {
    position: relative;
    width: 320px;
    position: relative;

    > .label {
        position: relative;
        color: #505050;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 133.333% */
        margin-bottom: 6px;
        display: block;
    }

    > .colorPicker {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: flex-start;
        width: 320px;
        height: 43px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #a5a5a5;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        column-gap: 11px;
        padding: 6px;
        box-sizing: border-box;

        > input {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
            border: none;
            padding: 0;
            transform: translateY(25px);
        }

        > .color {
            width: 31px;
            height: 31px;
            flex-shrink: 0;
            border-radius: 8px;
            border: none;
            padding: 0;
            outline: none;
        }

        > .value {
            color: #383838;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    }
}
