.website-frame {
    background-color: white;
    width: 100%;
    position: relative;

    &.framed {
        padding: 15px;
        box-sizing: border-box;
    }

    .outer-shadow {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    > span,
    div {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
    }

    > .headimage {
        background-image: url("/img/website/demo_image_1.jpg");
        width: 100%;
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        &.boxed {
            margin-left: 40px;
            margin-right: 40px;
            width: auto;
        }

        &.framed {
            margin-left: 15px;
            margin-right: 15px;
            width: auto;
        }

        &.margin-top {
            margin-top: 35px;
        }

        > div {
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            position: absolute;
            left: 60px;
            top: 50%;
            transform: translateY(-50%);
            width: 380px;
            height: 130px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            padding: 20px;
            box-sizing: border-box;
        }
    }

    > .content {
        min-height: 400px;

        &.boxed {
            margin-left: 40px;
            margin-right: 40px;
            width: auto;
        }

        &.framed {
            margin-left: 15px;
            margin-right: 15px;
            width: auto;
        }

        > div {
            padding: 10px 60px 10px 60px;
            box-sizing: border-box;
        }

        > .events {
            background-color: #343a40;

            > h2 {
                color: white;
            }

            > .cards {
                display: flex;
                flex-direction: row;
                column-gap: 30px;

                > .card {
                    width: 100%;
                    height: 100%;
                    background-color: white;
                    display: flex;
                    flex-direction: column;
                    border-radius: 4px;
                    overflow: hidden;

                    > span {
                        color: #212529;
                        padding: 15px;
                        box-sizing: border-box;
                    }

                    > .card-image {
                        position: relative;
                        width: 100%;
                        height: 150px;
                        background-image: url("/img/website/demo_card_image_1.jpg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;

                        > .card-calendar {
                            position: absolute;
                            border-radius: 4px;
                            left: 20px;
                            bottom: 20px;
                            width: 50px;
                            height: 60px;
                            background-color: white;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            > .day {
                                font-size: 24px;
                                font-weight: bold;
                            }

                            > .month {
                                font-size: 14px;
                            }
                        }
                    }

                    > .card-title {
                        border-bottom: 1px solid #dee2e6;
                        font-weight: bold;
                        font-size: 22px;
                    }

                    > .card-content {
                        font-size: 14px;
                    }
                }
            }
        }

        > .text {
            background-color: #ffffff;
            display: grid;
            grid-template-columns: auto 300px;
            grid-template-areas:
                "titletext titlelinks"
                "text links";
            column-gap: 40px;
            padding-bottom: 30px;
            box-sizing: border-box;

            > .title-text {
                grid-area: titletext;
                font-weight: bold;
                margin-bottom: 0;
            }

            > p {
                margin-top: 0;
                grid-area: text;
            }

            > .title-links {
                grid-area: titlelinks;
            }

            > .links {
                > .link {
                    border-bottom: 1px solid #dee2e6;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;
                    cursor: pointer;

                    > span {
                        color: #007bff;
                        font-weight: 700;
                    }

                    &:hover {
                        > span {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        > .interesting-pages {
            background-color: #f8f9fa;
            min-height: 200px;
            padding-bottom: 30px;
            box-sizing: border-box;

            > .title {
                font-weight: bold;
            }

            > .link-boxes {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                align-content: space-between;
                column-gap: 25px;

                > .link-box {
                    background-color: #6c757d;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 200px;
                    justify-content: space-between;
                    padding: 20px;
                    box-sizing: border-box;

                    > .link-title {
                        color: #212529;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    > .link-submenus {
                        display: flex;
                        flex-direction: column;
                        row-gap: 10px;

                        > span {
                            border-bottom: 1px solid #ffffff;
                            color: #ffffff;
                            line-height: 30px;
                            font-weight: 400;
                            font-size: 14px;
                            position: relative;

                            > svg {
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }

                    > .link-button {
                        > a {
                            cursor: pointer;
                            transition: all 0.2s ease-in-out;
                            color: #fff;
                            background-color: #007bff;
                            border-color: #007bff;
                            display: inline-block;
                            font-weight: 400;
                            text-align: center;
                            text-decoration: none;
                            vertical-align: middle;
                            user-select: none;
                            border: 1px solid transparent;
                            padding: 0.375rem 0.75rem;
                            font-size: 1rem;
                            line-height: 1.5;
                            border-radius: 0.25rem;

                            &:focus,
                            &:hover {
                                transform: scale(1.1);
                                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                            }
                        }
                    }
                }
            }
        }
    }
}
