.search-input-wraper {
    position: relative;
    padding: 0;
    box-sizing: border-box;

    > input {
        height: 100%;
        width: 100%;
        border: 1px solid #ced4da;
        color: #495057;
        border-radius: 0.25rem;
        box-sizing: border-box;
        padding: 10px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    > input:focus {
        color: #495057;
        background-color: #fff;
        border-color: #1a88ff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }

    > svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}
