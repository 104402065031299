.website-frame {
    > .footer {
        position: relative;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 60px;
        min-height: 50px;
        padding: 0 60px;
        box-sizing: border-box;

        &.boxed {
            margin-left: 40px;
            margin-right: 40px;
            width: auto;
        }

        &.framed {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 15px;
            width: auto;
        }

        > .copyright {
            text-wrap: nowrap;
            position: absolute;
            left: 60px;
        }

        > .quicklinks {
            width: 900px;
            min-width: 900px;
            max-width: 900px;
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;

            > span {
                border-left-width: 1px;
                border-left-style: solid;
                padding: 0 10px;
                font-size: 1rem;
                font-weight: 400;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }

                &.active {
                    text-decoration: underline;
                }
            }

            > span:first-child {
                border-left: none;
            }
        }

        > .icons {
            position: absolute;
            right: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            cursor: pointer;
        }
    }
}
