.dropdown-frame {
    position: relative;
    width: 320px;

    &.real-dropdown {
        width: 234px;
        > .real-dropdown-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 8px;
            border: 1px solid #a5a5a5;
            background: #fff;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            height: 40px;
            min-height: 40px;
            line-height: 40px;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;
            user-select: none;
            -webkit-user-select: none;
        }

        > .dropdown {
            top: 45px;
        }
    }

    > .dropdown {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        min-height: 44px;
        max-height: 220px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #a5a5a5;
        background: #fff;
        box-shadow: 0px 4px 3px 0px #00000014;
        top: 75px;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 120;

        .option-label {
            display: block;
            width: 100%;
            height: 44px;
            min-height: 44px;
            padding: 12px 10px;
            box-sizing: border-box;
            overflow: hidden;
            cursor: pointer;
            user-select: none;
            -webkit-user-select: none;
        }

        label {
            width: 100%;
        }

        input[type="file"] {
            display: none;
        }

        .option-label:hover {
            background-color: rgba(122, 33, 130, 0.1);
        }
    }

    .color-preview {
        width: 60px;
        height: 20px;
        display: block;
        position: absolute;
        border-radius: 25px;
        right: 20px;
        pointer-events: none;

        &.input {
            bottom: 12px;
            right: 25px;
        }
    }

    > .clear {
        position: absolute;
        display: block;
        width: 25px;
        height: 25px;
        color: #a5a5a5;
        text-align: center;
        right: 10px;
        line-height: 25px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    > .clear:hover {
        color: #000000;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1d9f3;
        border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #7a2182;
        border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
