.switch-frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    position: relative;
    width: 320px;

    > .label {
        color: #505050;
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
    }

    > .switch {
        position: relative;
        display: inline-block;
        width: 52px;
        min-width: 52px;
        height: 31px;

        > input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        > .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border: 1px solid #a5a5a5;
            border-radius: 25px;
        }

        > .slider:before {
            position: absolute;
            content: "";
            height: 25px;
            width: 25px;
            left: 2px;
            bottom: 2px;
            background-color: #a5a5a5;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }

        > input:checked + .slider {
            background-color: #7a2182;
            border: 1px solid #7a2182;
        }

        > input:focus + .slider {
            box-shadow: 0 0 1px #a5a5a5;
        }

        > input:checked + .slider:before {
            -webkit-transform: translateX(22px);
            -ms-transform: translateX(22px);
            transform: translateX(22px);
            background-color: white;
        }
    }
}
