.textInput-frame {
    position: relative;
    width: 320px;

    > .textInput {
        > input {
            width: 100%;
            height: 44px;
            flex-shrink: 0;
            border-radius: 8px;
            border: 1px solid #a5a5a5;
            background: #fff;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            padding: 12px 10px;
            box-sizing: border-box;
            color: #383838;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }

        > input::placeholder {
            color: #a5a5a5;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    }

    > .label {
        color: #505050;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 133.333% */
        margin-bottom: 6px;
        display: block;
    }

    > .description {
        display: block;
        margin-top: 6px;
        color: #505050;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
}
