.header-button {
    display: flex;
    align-items: center;
    column-gap: 15px;
    border-radius: 0.25rem;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    user-select: none;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
}
